import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {bankingActions, transactionActions} from '../../../../state/actions';
import {bankingUserTypesConstants, localStorageKeysConstants, SCAActionsConstants} from '../../../../constants';
import useIsEmployeeChanged from '../../../../hooks/useIsEmployeeChanged';
import AuthenticationWindow from '../../CardsPage/AuthenticationWindow';
import {scaHelpers} from '../../../../utils/helpers';

const SCA_OPERATION_NAME = SCAActionsConstants.BALANCE_LOADING;

const {CLOSED_BALANCE_AUTH} = localStorageKeysConstants;

const BalanceLoading = ({
  enabledLoading,
  getUserWalletsMinimal,
  getCardUsers,
  getWalletBalance,
  isKnownEmployee,
  isEnabledBanking,
  legalPersonId,
  storeBalanceDetails,
  storeBankingUserId,
  storeLegalPersonDetails,
  storeWireDetails,
  wireDetails,

  errorCallback,
  successCallback
}) => {
  const [startLoading, setStartLoading] = useState(false);
  const [t] = useTranslation('main');
  const [authWindowProps, setAuthWindowProps] = useState({open: false});

  const isEmployeeChanged = useIsEmployeeChanged(true);

  useEffect(() => {
    // do not load data if the user previously closed the balance loading window
    const closedBalanceAuth = localStorage.getItem(CLOSED_BALANCE_AUTH) === 'true';
    if (closedBalanceAuth) {
      storeBalanceDetails();
      return;
    }

    if (enabledLoading && isEnabledBanking && isKnownEmployee) {
      if (legalPersonId) {
        loadWalletDetails(legalPersonId);
      } else {
        setStartLoading(true);
        getCardUsers(
          {user_type: bankingUserTypesConstants.LEGAL_PERSON},
          (users) => {
            if (users.length > 0) {
              const legalPersonUser = users[0];
              const userId = legalPersonUser.id;
              storeLegalPersonDetails(legalPersonUser);
              loadWalletDetails(userId);
            } else {
              storeBalanceDetails(undefined);
              setStartLoading(false);
            }
          },
          () => {
            storeBalanceDetails(undefined);
            storeWireDetails(undefined);
            setStartLoading(false);
          }
        )
      }
    }
  }, [enabledLoading, isEnabledBanking, isKnownEmployee, isEmployeeChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadWalletBalance = ({userId, walletId} = {}) => {
    const headers = scaHelpers.getAuthHeaders(SCA_OPERATION_NAME);
    getWalletBalance({
      headers,
      userId,
      walletId,
      successCallback: (balance) => {
        localStorage.removeItem(CLOSED_BALANCE_AUTH);
        storeBalanceDetails(balance, true);
        setStartLoading(false);
        successCallback && successCallback();
      },
      errorCallback: (response) => {
        scaHelpers.SCAResponseCallback({
          response,
          scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
          errorCallback: () => {
            errorCallback && errorCallback();
            storeBalanceDetails(undefined);
            setStartLoading(false);
          }
        });
      }
    });
  }

  const loadWalletDetails = (userId) => {
    let walletUserId = userId || legalPersonId;
    !startLoading && setStartLoading(true);
    storeBankingUserId(walletUserId);

    const walletId = wireDetails?.walletId;
    if (walletId) {
      loadWalletBalance({userId: walletUserId, walletId: walletId});
    } else {
      getUserWalletsMinimal({
        id: walletUserId,
        successCallback: (wallets) => {
          if (wallets.length > 0) {
            const wallet = wallets[0];
            storeWireDetails(wallet, true);
            loadWalletBalance({userId: walletUserId, walletId: wallet.id});
          } else {
            errorCallback && errorCallback();
            storeWireDetails(undefined);
            setStartLoading(false)
          }
        },
      })
    }
  }

  const handleCloseAuthModal = () => {
    localStorage.setItem(CLOSED_BALANCE_AUTH, 'true');
    setAuthWindowProps({...authWindowProps, open: false});
    storeBalanceDetails();
    errorCallback && errorCallback();
  }

  const handleOnSuccessAuth = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    loadWalletDetails();
  }

  return (
    <>
      <AuthenticationWindow
        {...authWindowProps}
        authModalProps={{title: t('balanceInquiry')}}
        handleCancel={handleCloseAuthModal}
        onSuccess={handleOnSuccessAuth}
        operationName={SCA_OPERATION_NAME}
      />
    </>
  );
}

BalanceLoading.propTypes = {
  enabledLoading: PropTypes.bool,
  errorCallback: PropTypes.func,
  successCallback: PropTypes.func
}

BalanceLoading.defaultProps = {
  enabledLoading: true,
}

const mapStateToProps = state => {
  const {isKnownEmployee} = state.user;
  const {cards, isEnabledBanking, legalPerson} = state.banking;
  const {wireDetails} = state.transaction;
  const legalPersonId = legalPerson?.id || undefined;
  const isExistsBankingUser = state.banking?.verification?.isCreatedUser;

  return {
    cards,
    legalPersonId,
    isEnabledBanking: isEnabledBanking && isExistsBankingUser,
    isKnownEmployee,
    wireDetails
  }
}

const mapDispatchToProps = {
  getUserWalletsMinimal: bankingActions.getUserWalletsMinimal,
  getCardUsers: bankingActions.getCardsUsers,
  getWalletBalance: bankingActions.getUserWalletBalance,
  storeBalanceDetails: transactionActions.storeBalanceDetails,
  storeBankingUserId: transactionActions.storeBankingUserId,
  storeLegalPersonDetails: bankingActions.storeLegalPersonDetails,
  storeWireDetails: transactionActions.storeWireDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceLoading);
